import { useEffect } from "react";
import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import {useSnackbar} from "notistack";
import { ThemeProvider } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import CssBaseline from "@mui/material/CssBaseline";

import "./i18n";

import RTL from "./components/RTL";
import useScrollReset from "./hooks/useScrollReset";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./hooks/contexts/SettingsContext";
import { gtmConfig } from "./config";
import gtm from "./lib/gtm";
import routes from "./routes";
import { createTheme } from "./theme";
import { ModalProvider } from "./hooks/contexts/ModalContext";
import { TourProvider } from "./hooks/contexts/TourContext";
import ShepherdTourTheme from "./shepherd/ShepherdTourTheme";
import SplashScreen from "./components/SplashScreen";
import {RootState, useSelector} from "./store";

const GLOBAL_SNACKBAR_KEY = 'global_snackbar';

const App: FC = () => {
  const content = useRoutes(routes);
  const { isLoading } = useAuth0();
  useScrollReset();

  const globalToastSelector = useSelector((state:RootState) => state.globalToast.toast);

  // global snackbar connected to redux
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (globalToastSelector) {
      enqueueSnackbar(globalToastSelector?.message, {
        variant: globalToastSelector?.type,
        key: GLOBAL_SNACKBAR_KEY,
        persist: true,
      });
    } else {
      closeSnackbar(GLOBAL_SNACKBAR_KEY);
    }
  }, [globalToastSelector, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    if (!gtm.initialized) {
      gtm.initialize(gtmConfig);
    }
  }, []);

  return (
    <ModalProvider>
      <TourProvider>
        <ShepherdTourTheme />

        <SettingsProvider>
          <SettingsConsumer>
            {({ settings }) => (
              <ThemeProvider
                theme={createTheme({
                  direction: settings.direction,
                  responsiveFontSizes: settings.responsiveFontSizes,
                  mode: settings.theme,
                })}
              >
                <RTL direction={settings.direction}>
                  <CssBaseline />
                    {!isLoading ? content : <SplashScreen />}
                </RTL>
              </ThemeProvider>
            )}
          </SettingsConsumer>
        </SettingsProvider>
      </TourProvider>
    </ModalProvider>
  );
};

export default App;
